<template>
  <div class="page-container">
    <!-- Section We Are Here To Help Start -->
    <div class="section-wrapper">
      <div class="content-wrapper">
        <Content1 :title="$t('weAreHereToHelp[0].title')"></Content1>
      </div>
    </div>
    <!-- Section We Are Here To Help End -->

    <!-- Section Contact Us Start -->
    <div
      v-intersect.once="onIntersect"
      :class="{ 'section-wrapper': !isMobile }"
    >
      <div :class="{ 'content-wrapper': !isMobile }">
        <v-row no-gutters>
          <v-col cols="12" md="6">
            <v-fade-transition>
              <v-card
                v-if="isIntersecting"
                v-intersect.once="onIntersectScheduleConsultationCard"
                height="100%"
                tile
                elevation="0"
                color="darkLayer"
                class="schedule-consultation-card"
                style="
                  transition-delay: 0.3s !important;
                  transition-duration: 0.5s !important;
                "
              >
                <div class="pa-6 pa-md-12 surface--text">
                  <v-scroll-x-transition>
                    <v-card-title
                      v-if="isIntersectingScheduleConsultationCard"
                      class="px-0 font-cormorant text-h4 text-md-h3 word-break-break-word mb-4"
                      style="
                        transition-delay: 0.5s !important;
                        transition-duration: 0.5s !important;
                      "
                    >
                      {{ $t('weAreHereToHelp[1].title') }}
                    </v-card-title>
                  </v-scroll-x-transition>

                  <v-scroll-x-transition :group="true" tag="div">
                    <template v-for="(i, index) in contactList">
                      <v-row
                        v-intersect.once="
                          onIntersectScheduleConsultationCardRow
                        "
                        v-if="isIntersectingScheduleConsultationCard"
                        :key="index"
                        no-gutters
                        class="text-md-body-1 mb-4"
                        :style="`
                          transition-delay: 0.${5 + index}s !important;
                          transition-duration: 0.5s !important;
                        `"
                      >
                        <v-col cols="3">{{ $t(`text.${i.title}`) }}:</v-col>
                        <v-col cols="9">{{ i.contact }}</v-col>
                      </v-row>
                    </template>
                  </v-scroll-x-transition>
                </div>
              </v-card>
            </v-fade-transition>
          </v-col>
          <v-col cols="12" md="6">
            <v-fade-transition>
              <v-card
                v-intersect.once="onIntersectContactFormCard"
                v-if="isIntersectingScheduleConsultationCardRow"
                tile
                height="100%"
                elevation="0"
                color="greyCard"
                class="pa-md-10"
                style="
                  transition-delay: 1s !important;
                  transition-duration: 0.5s !important;
                "
              >
                <div class="pa-6 px-md-12 py-md-6">
                  <v-scroll-x-transition>
                    <v-card-subtitle
                      v-if="!isMobile && isIntersectingContactFormCard"
                      class="px-0 text-h6 font-weight-bold title--text"
                      style="
                        transition-delay: 1s !important;
                        transition-duration: 0.5s !important;
                      "
                    >
                      {{ $t('weAreHereToHelp[1].title2') }}
                    </v-card-subtitle>
                  </v-scroll-x-transition>
                  <v-form ref="contactForm" class="contact-form">
                    <v-scroll-x-transition :group="true">
                      <template v-for="(i, index) in formFields">
                        <div
                          v-if="isIntersectingContactFormCard"
                          :key="index"
                          :style="`
                          transition-delay: 1.${2 + index}s !important;
                          transition-duration: 0.5s !important;
                        `"
                        >
                          <v-textarea
                            v-if="i.model == 'description'"
                            v-model="contact[i.model]"
                            dense
                            outlined
                            :label="$t(`text.${i.label}`)"
                            :rules="i.rules"
                          ></v-textarea>
                          <v-text-field
                            v-else
                            v-model="contact[i.model]"
                            dense
                            outlined
                            :label="$t(`text.${i.label}`)"
                            :rules="i.rules"
                          ></v-text-field>
                        </div>
                      </template>
                    </v-scroll-x-transition>
                    <v-scroll-x-transition>
                      <v-btn
                        v-if="isIntersectingContactFormCard"
                        depressed
                        tile
                        color="primary"
                        min-width="145"
                        min-height="42"
                        class="mt-6 title--text font-weight-black"
                        style="
                          transition-delay: 1.6s !important;
                          transition-duration: 0.5s !important;
                        "
                        @click="submitForm"
                        >{{ $t('text.submit') }}</v-btn
                      >
                    </v-scroll-x-transition>
                  </v-form>
                </div>
              </v-card>
            </v-fade-transition>
          </v-col>
        </v-row>
        <div v-intersect.once="onIntersectGoogleMapIframe">
          <v-fade-transition>
            <iframe
              v-if="isIntersectingGoogleMapIframe"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1991.846681121354!2d101.65947752658833!3d3.175178942343173!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc488a6c8ffe13%3A0x7a7aa2b9dd363934!2sSoho%20KL%2C%202%2C%20Jalan%20Solaris%2C%20Solaris%20Mont%20Kiara%2C%2050480%20Kuala%20Lumpur%2C%20Wilayah%20Persekutuan%20Kuala%20Lumpur!5e0!3m2!1sen!2smy!4v1693460775393!5m2!1sen!2smy"
              width="100%"
              height="450"
              allowfullscreen=""
              loading="lazy"
              style="
                border: 0;
                transition-delay: 0.5s !important;
                transition-duration: 1s !important;
              "
            ></iframe>
          </v-fade-transition>
        </div>
      </div>
    </div>
    <!-- Section Contact Us End -->
  </div>
</template>

<script>
import { uiHelper, formValidationHelper } from '@/utils'
import {
  CONTACT_APPLICATION_CREATE_CONTACT_APPLICATION,
  CONTACT_APPLICATION_INITIAL_CREATE_CONTACT_APPLICATION_STATE
} from '@/store/contact-application.module'

export default {
  name: 'ContactUs',
  data: () => ({
    contactList: [
      {
        title: 'tel',
        contact: '+603 6420 7841'
      },
      {
        title: 'whatsapp',
        contact: '+6017 750 8408'
      },
      {
        title: 'fax',
        contact: '+603 6420 7842'
      },
      {
        title: 'email',
        contact: 'general@lkkco.com.my'
      },
      {
        title: 'address',
        contact:
          'K-6-6, Solaris Mont Kiara, No.2, Jalan Solaris, 50480 Kuala Lumpur, Malaysia.'
      }
    ],
    formFields: [
      {
        label: 'name',
        model: 'name',
        rules: formValidationHelper.requiredRules()
      },
      {
        label: 'phoneNumber',
        model: 'phoneNumber',
        rules: formValidationHelper.numericRules()
      },
      {
        label: 'emailAddress',
        model: 'email',
        rules: formValidationHelper.emailRules()
      },
      {
        label: 'description',
        model: 'description',
        rules: formValidationHelper.requiredRules()
      }
    ],
    contact: {
      name: '',
      phoneNumber: '',
      email: '',
      description: ''
    },
    isIntersecting: false,
    isIntersectingScheduleConsultationCard: false,
    isIntersectingScheduleConsultationCardRow: false,
    isIntersectingContactFormCard: false,
    isIntersectingGoogleMapIframe: false
  }),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    createContactApplicationComplete() {
      return this.$store.state.contactApplication.createContactApplication
        .complete
    }
  },
  watch: {
    createContactApplicationComplete() {
      let response = this.$store.state.contactApplication
        .createContactApplication

      if (response.complete) {
        this.createContactApplicationCompleteAction(response)
      }
    }
  },
  methods: {
    onIntersectGoogleMapIframe(entries) {
      let isIntersecting = entries[0].isIntersecting

      if (isIntersecting) {
        this.isIntersectingGoogleMapIframe = isIntersecting
      }
    },
    onIntersectContactFormCard(entries) {
      let isIntersecting = entries[0].isIntersecting

      if (isIntersecting) {
        this.isIntersectingContactFormCard = isIntersecting
      }
    },
    onIntersectScheduleConsultationCardRow(entries) {
      let isIntersecting = entries[0].isIntersecting

      if (isIntersecting) {
        this.isIntersectingScheduleConsultationCardRow = isIntersecting
      }
    },
    onIntersectScheduleConsultationCard(entries) {
      let isIntersecting = entries[0].isIntersecting

      if (isIntersecting) {
        this.isIntersectingScheduleConsultationCard = isIntersecting
      }
    },
    onIntersect(entries) {
      let isIntersecting = entries[0].isIntersecting

      if (isIntersecting) {
        this.isIntersecting = isIntersecting
      }
    },
    createContactApplicationCompleteAction(response) {
      let color = response.code == 0 ? 'success' : 'error'
      let message = uiHelper.arrayToTrimmedStringWithSpace(response.message)

      this.openRootSnackBar(color, message, 5000)
      this.initialCreateContactApplicationState()
    },
    submitForm() {
      if (this.$refs.contactForm.validate()) {
        this.createContactApplication(this.contact)
      }
    },
    createContactApplication(data) {
      this.$store.dispatch(CONTACT_APPLICATION_CREATE_CONTACT_APPLICATION, {
        data
      })
    },
    initialCreateContactApplicationState() {
      this.$store.dispatch(
        CONTACT_APPLICATION_INITIAL_CREATE_CONTACT_APPLICATION_STATE
      )
    },
    openRootSnackBar(color, message, timeout) {
      this.$parent.$parent.openSnackBar(color, message, timeout)
    }
  }
}
</script>

<style lang="scss">
.contact-form {
  fieldset {
    background-color: var(--v-background-base) !important;
  }
}
.schedule-consultation-card {
  background-image: url('/img/png/bg-contact-us-2.png');
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
